import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DisableMainButton from '../../components/buttons/DisableMainButton';
import MainButton from '../../components/buttons/MainButton';
import HomeHeader from '../../components/headers/HomeHeader';
import { ERROR_MAIL_REGISTED, LENGTH_ZIP, MAX_NAME, MIN_PASSWORD, REGEX_MAIL_REGISTER, REGEX_NUMBER, REGISTER, VALIDATE_ERROR } from '../../helper/helper';
import firebase from '../../services/firebase';
import { serverApiNoAuth } from '../../services/serverApi';
import './styles.scss';

const Register = (props) => {
  const [mail, setMail] = useState("");
  const [confirmMail, setConfirmMail] = useState("");
  const [password, setPassword] = useState("");
  const [nickName, setNickName] = useState("");
  const [zip, setZip] = useState("");
  const [isRegister, setIsRegister] = useState(Boolean);
  const [isShowPassword, setIsShowPassword] = useState(Boolean)
  const [gender, setGender] = useState('')
  const [birth, setBirth] = useState('')
  const [isError, setIsError] = useState(Boolean)
  const [isValidMail, setIsValidMail] = useState(false)
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [isValidZip, setIsValidZip] = useState(false)
  const [isValidNickName, setIsValidNickName] = useState(false)
  const [isValidMailFormat, setIsValidMailFormat] = useState(false)
  const [isLoadingSpin, setIsLoading] = useState(false)

  let yearOptions = [];
  for (let index = new Date().getFullYear(); index >= 1899; index--) {
    yearOptions.push(index);
  }

  const checkEmpty = () => {
    if (!isRegister) {
      return false
    }
    if (mail != "" && confirmMail != "" && password != "" && zip != "") {
      return true
    }
    setIsRegister(false)
    return false
  }

  const checkEnableRegisterButton = () => {
    if (mail != "" && confirmMail != "" && password != "" && zip != "" && nickName != "") {
      return true
    }
    return false
  }

  useEffect(() => {

  }, [isValidMail])

  const doRegister = async () => {
    setIsRegister(true)
    validateMailFormat()
    validateConfirmMail()
    validatePassword()
    validateNickName()
    validateZip()
    let isPass = false
    if (REGEX_MAIL_REGISTER.test(mail) && confirmMail == mail  && password.length >= MIN_PASSWORD && REGEX_NUMBER.test(zip) && zip.length == LENGTH_ZIP && nickName.length <= MAX_NAME) {
      isPass = true
    } else {
      isPass = false
    }
    if (isPass) {
      setIsLoading(true)
      try {
        window.localStorage.setItem(REGISTER, REGISTER)
        const userCredential = await firebase.auth().createUserWithEmailAndPassword(mail, password);
        // await userCredential.user.sendEmailVerification({
        //   url: window.location.origin + '/login'
        // });
        const crypto = require('crypto');
        const myKey = crypto.createCipher('aes-128-cbc', 'mypassword');
        let hasPass = myKey.update(password, 'utf8', 'hex')
        hasPass += myKey.final('hex');
        const requestParams = {
          "mail": mail,
          "nickName": nickName,
          "password": hasPass,
          "postalCode": zip,
          "address": "",
          "gender": gender,
          "dateOfBirth": birth.toString(),
          "id": userCredential.user.uid
        }
        const response = await serverApiNoAuth.post('players/register', requestParams);
        window.location.href = '/login';
        // await firebase.auth().signOut()
        // if (response.status == STATUS_CODE_201) {
        //   window.location.pathname = '/register-mail-sent';
        // }        
      } catch (error) {
        let errorCode = error.code;
        let errorMessage = error.message;
        console.log("Error: ", errorCode, " => ", errorMessage);
        if (errorCode == undefined) {
          setIsLoading(false)
          return
        }
        if (errorCode == ERROR_MAIL_REGISTED) {
          setIsLoading(false)
          setIsValidMail(true)
          var e = document.getElementById("register-header")
          e.scrollIntoView(true)
        }
        return
      }
    } else {
      // NOT ABLE TO CALL API
    }
  }

  const validateConfirmMail = () => {
    if (confirmMail != mail) {
      if (!isValidMail) {
        var e = document.getElementById("register-header")
        e.scrollIntoView(true)
      }
      setIsValidConfirmPassword(true)
      return
    }
    setIsValidConfirmPassword(false)
  }

  const validatePassword = () => {
    if (password.length < MIN_PASSWORD) {
      var e = document.getElementById("register-header")
      e.scrollIntoView(true)
      setIsValidPassword(true)
      return
    }
    setIsValidPassword(false)
  }

  const validateNickName = () => {
    if (nickName.length > MAX_NAME) {
      setIsValidNickName(true)
      return
    }
    setIsValidNickName(false)
  }

  const validateZip = () => {
    if (!REGEX_NUMBER.test(zip) || zip.length != LENGTH_ZIP) {
      setIsValidZip(true)
      return
    }
    setIsValidZip(false)
  }

  const handleChangeYear = (event) => {
    setBirth(event.target.value)
  }

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword)
  }

  const validateMailFormat = () => {
    if (!REGEX_MAIL_REGISTER.test(mail)) {
      setIsValidMail(true)
      setIsValidMailFormat(true)
      return
    }
    setIsValidMailFormat(false)
    setIsValidMail(false)
  }

  useEffect(() => {
    document.body.className = 'signin';
    return () => { document.body.className = ''; }
  }, [])

  return (
    <div className="register">
      <HomeHeader />
      <div className="tabingo" id="register-header">
        <img src="icon/rainbow_kirakira_L.svg" className="imgLeft" alt="rainbow_kirakira_L"/>
        <h2>新規会員登録</h2>
        <img src="icon/rainbow_kirakira_R.svg" className="imgRight" alt="rainbow_kirakira_R"/>
      </div>
      <div className="register-content">
        <div className="text-require">
          <div className="register-require"><p>*必須項目</p></div>
        </div>

        <div className="register-form">
          <div className="title" id="mail">
            <p className={isValidMail ? "validate-error" : ""}>メールアドレス</p>
            <div className={isValidMail ? "aterisk validate-error" : "aterisk"}>*</div>
          </div>
          <input
            className={isValidMail ? VALIDATE_ERROR : ""}
            type="text"
            placeholder="xxx@tabingo.com"
            value={mail} 
            onChange={(e) => {
              setMail(e.target.value)
              setIsValidMail(false)
            }}/>
            {isValidMail && <p className={VALIDATE_ERROR}>{isValidMailFormat ? "メールアドレスを正しく入力してください" : "登録済みのメールアドレスです"}</p>}
        </div>

        <div className="register-form">
          <div className="title" id="confirm-mail">
            <p className={isValidConfirmPassword ? VALIDATE_ERROR : ""}>メールアドレス(確認用)</p>
            <div className={isValidConfirmPassword ? "aterisk " + VALIDATE_ERROR : "aterisk"}>*</div>
          </div>
          <input
            className={isValidConfirmPassword ? VALIDATE_ERROR : ""}
            type="text"
            placeholder="xxx@tabingo.com"
            value={confirmMail} 
            onChange={(e) => {setConfirmMail(e.target.value)}}/>
          {isValidConfirmPassword && <p className={VALIDATE_ERROR}>メールアドレスと一致しません</p>}
        </div>

        <div className="register-form">
          <div className="title" id="password">
            <p className={isValidPassword ? VALIDATE_ERROR : ""}>パスワード</p>
            <div className={isValidPassword ? "aterisk " + VALIDATE_ERROR : "aterisk"}>*</div>
          </div>
          <input 
            type={isShowPassword ? "text" : "password"}
            className={isValidPassword ? "password-input " + VALIDATE_ERROR : "password-input "}
            value={password} 
            onChange={(e) => {setPassword(e.target.value)}}/>
          <img 
            className='password-eye' 
            src={isShowPassword ? 'icon/eye-off.svg' : 'icon/eye-on.svg'}
            onClick={handleShowPassword}/>
          {!isValidPassword && <div className="descript">6 文字以上で、文字と数字を組み合わせた安全なパスワードを設定してください</div>}
          {isValidPassword && <p className={VALIDATE_ERROR}>6 文字以上で、文字と数字を組み合わせた安全なパスワードを設定してください</p>}
        </div>

        <div className="register-form">
          <div className="title" id="nick-name">
            <p className={isValidNickName ? VALIDATE_ERROR : ""}>ニックネーム</p>
            <div className={isValidNickName ? "aterisk " + VALIDATE_ERROR : "aterisk"}>*</div>
          </div>
          <input 
            type="text"
            className={isValidNickName ? VALIDATE_ERROR : ""}
            value={nickName} 
            onChange={(e) => {setNickName(e.target.value)}}/>
          {!isValidNickName && <div className="descript">10文字以内で入力してください</div>}
          {isValidNickName && <p className={VALIDATE_ERROR}>ニックネームは10文字以内で入力してください</p>}
        </div>

        <div className="register-form">
          <div className="title">
            <p>性別</p>
          </div>
          <div className='group-check' onChange={(e) => {setGender(e.target.value)}}>
            <label class="gender-item">
              <input type="radio" name="radio" value="MALE"/>
              <span class="gender"></span>男性
            </label>
            <label class="gender-item">
              <input type="radio" name="radio" value="FEMALE"/>
              <span class="gender"></span>女性
            </label>
            <label class="gender-item">
              <input type="radio" name="radio" value="OTHER"/>
              <span class="gender"></span>その他
            </label>          
          </div>
        </div>
        <div className="register-form">
          <div className="title">
            <p>生年</p>
          </div>
          <select className="register-year" onChange={handleChangeYear} value={birth}>
            <option value={''}>年</option>
            {
              yearOptions.map((year, index) => (
                <option key={index} value={year}>{year + '年'}</option>
              ))
            }
          </select>
          <p className='dropdown-icon'>▼</p>
        </div>

        <div className="register-form">
          <div className="title">
            <p className={isValidZip ? VALIDATE_ERROR : ""}>郵便番号</p>
            <div className={isValidZip ? "aterisk " + VALIDATE_ERROR : "aterisk"}>*</div>
          </div>
          <div className="zip-code">
            <input
              className={isValidZip ? "register-year zip " + VALIDATE_ERROR : "register-year zip"}
              name="year"
              type="text"
              placeholder="1234567"
              value={zip} 
              onChange={(e) => {setZip(e.target.value)}}/>
            <div className="descript-vertically">
              <p>半角数字</p>
              <p>-(ハイフン)なしで入力してください</p>
            </div>
          </div>
          {isValidZip && <p className={VALIDATE_ERROR}>郵便番号を入力してください</p>} 
        </div>
        <div className="button-area">
          {checkEnableRegisterButton() && <MainButton isLoading={isLoadingSpin} name ="会員登録" handleClick={doRegister}/>}
          {!checkEnableRegisterButton() && <DisableMainButton name ="会員登録" />}
        </div>    
        <div className="blank-bottom"></div>
      </div>
    </div> 
  )
}

export default Register