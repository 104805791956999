import { detailPlayer } from 'common/src/api/player';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CopyRight from '../../../components/copyRights/CopyRight';
import QuestionHeader from '../../../components/headers/QuestionHeader';
import Loading from '../../../components/loading/Loading';
import { backgroundGlay } from '../../../helper/helper';
import { getEventDetail, getPlayerEvents } from '../../../redux/event/action';
import { getUserInfo } from '../../../redux/user/action';
import { serverApi } from '../../../services/serverApi';
import BingoListBottom from './BingoListBottom';
import BingoSelectTop from './BingoSelectTop';
import './styles.scss';

const BingoSelectScreen = () => {
    const playerId = window.localStorage.getItem('user_id');
    const { playerEvent, isGettingEvent, eventDetail, isGettingEventDetail } = useSelector((state) => state.event);
    const { userInfo } = useSelector((state) => state.user)
    const dispatch = useDispatch();
    const eventDetailId = window.localStorage.getItem('eventId');

    const getInfoUser = async (playerId) => {
        const result = await serverApi(detailPlayer(playerId))
        if (result.data) {
            const player = result.data.data
            dispatch(getUserInfo(player))
        }
    }

    useEffect(() => {
        if (!playerId) {
            return;
        }
        if (playerEvent.length <= 0) {
            dispatch(getPlayerEvents({ playerId: playerId }));
        }
        if (!isGettingEventDetail) {
            if (eventDetailId !== null) {
                dispatch(getEventDetail({ eventId: eventDetailId }));
            }
        }

        if (userInfo === undefined || userInfo.activities === undefined) {
            getInfoUser(playerId);
        }
    }, [eventDetailId, userInfo])

    return (
        <>
            <QuestionHeader name="【初級】 熊本市内" />
            {isGettingEvent || !isGettingEventDetail || userInfo.activities === undefined ? <Loading /> :
                <div className="bingo-select-screen">
                    <BingoSelectTop eventDetail={eventDetail} userInfo={userInfo}/>
                    <BingoListBottom />
                    <CopyRight backgroundColor={backgroundGlay} />
                </div>
            }

        </>
    )
}
export default BingoSelectScreen;